body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.tenant-manager {
  max-width: 100%; /* Allow full width */
  width: 100%; /* Use full width */
  margin: 0 auto;
  padding: 5px 20px 10px 20px;
  box-sizing: border-box;
}

h1 {
  color: #2e7d32;
  text-align: center;
  padding: 0 0 0 0;
}

h2 {
  text-align: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%; /* Ensure full width */
}

.form-container {
  width: 100%; /* Use full width */
  background-color: #1e3a2e;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  box-sizing: border-box;
}

.form-container h2 {
  color: white; /* Light colored text */
  text-align: center; /* Center the text */
  margin-bottom: 20px; /* Add some space below the heading */
  font-weight: normal; /* Optional: makes the text less bold */
}

.tenant-form {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.form-group {
  flex: 1;
  min-width: 0; /* Allows flex items to shrink below their minimum content size */
}

.form-input, .form-select {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: #2a4f3e;
  color: white; /* This ensures the entered text is white */
  border-radius: 4px;
  box-sizing: border-box;
}

.form-input::placeholder,
.form-select::placeholder {
  color: rgba(255, 255, 255, 0.7); /* White with 70% opacity for slight distinction */
  opacity: 1; /* Needed for some browsers */
}

/* For Internet Explorer */
.form-input:-ms-input-placeholder,
.form-select:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

/* For Microsoft Edge */
.form-input::-ms-input-placeholder,
.form-select::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.btn {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
  flex-shrink: 0; /* Prevents the button from shrinking */
}

.btn:hover {
  background-color: #45a049;
}

.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.tenant-table {
  width: 100%; /* Ensure table uses full width */
  border-collapse: separate;
  border-spacing: 0;
  font-size: 12px;
  color: white;
}

.tenant-table th, .tenant-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #2a4f3e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tenant-table th {
  background-color: #2a4f3e; /* Slightly lighter green for table headers */
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Remove fixed widths for columns */
.tenant-table th, .tenant-table td {
  min-width: 100px; /* Set a minimum width for all columns */
}

/* Adjust the actions column */
.tenant-table th:last-child, .tenant-table td:last-child {
  min-width: 200px; /* Increase width for action buttons */
  white-space: nowrap;
}

.tenant-table .btn {
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 5px;
  display: inline-block;
  width: auto;
}

/* Column widths */
.tenant-table th:nth-child(1), .tenant-table td:nth-child(1) { width: 15%; } /* Name */
.tenant-table th:nth-child(2), .tenant-table td:nth-child(2) { width: 20%; } /* Email */
.tenant-table th:nth-child(3), .tenant-table td:nth-child(3) { width: 10%; } /* Type */
.tenant-table th:nth-child(4), .tenant-table td:nth-child(4) { width: 20%; } /* GUID */
.tenant-table th:nth-child(5), .tenant-table td:nth-child(5) { width: 15%; } /* Status */
.tenant-table th:nth-child(6), .tenant-table td:nth-child(6) { width: 10%; } /* Date */
.tenant-table th:nth-child(7), .tenant-table td:nth-child(7) { width: 10%; } /* Actions */

.error {
  color: #ff6b6b;
  margin-bottom: 10px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative; /* Ensure the spinner is positioned relative to the button */
}

.spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-right: 5px; /* Add some space between spinner and text */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.table-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-x: auto;
  box-sizing: border-box;
  margin-top: 20px;
}

.tenant-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 14px;
  color: #333;
}

.tenant-table th, .tenant-table td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #eaeaea;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tenant-table th {
  background-color: #ffffff;
  color: #333;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Remove the previous column width constraints */
.tenant-table th, .tenant-table td {
  min-width: auto;
}

/* Status styling */
.status-complete {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 6px 12px;
  border-radius: 12px;
  font-size: 12px;
  display: inline-block;
}

/* Icon styling */
.type-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

/* Action buttons */
.btn {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  margin-right: 8px;
  border: 1px solid transparent;
}

.btn-primary {
  background-color: #f0f4ff;
  color: #3b62e4;
  border-color: #e0e7ff;
}

.btn-danger {
  background-color: #fff1f0;
  color: #2c0707;
  border-color: #ffe0e0;
}

.btn-danger:hover {
  background-color: #daa19d;
}

/* Row hover effect */
.tenant-table tbody tr:hover {
  background-color: #f8f9fa;
}

.sign-out-container {
  display: none;
}

.sign-out-link {
  color: #4caf50;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.sign-out-link:hover {
  color: #45a049;
  text-decoration: underline;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
}

.login-container h1 {
  color: #2e7d32;
  margin-bottom: 20px;
  text-align: center;
}

.login-message {
  font-size: 24px;
  color: #1e3a2e;
  margin-bottom: 10px;
  text-align: center;
}

.login-instruction {
  font-size: 18px;
  color: #2a4f3e;
  margin-bottom: 30px;
  text-align: center;
}

.login-btn {
  padding: 12px 24px;
  font-size: 18px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-btn:hover {
  background-color: #45a049;
}

nav {
  background-color: #1e3a2e;
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ot-logo {
  height: 30px;
  padding: 10px;
}

.nav-links {
  margin-left: auto;
  margin-right: 20px;
}

.nav-links a, .nav-auth a {
  color: white;
  text-decoration: none;
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-links a:hover, .nav-auth a:hover {
  background-color: #2a4f3e;
}

.nav-auth a {
  margin-right: 0;
}

nav a {
  color: white;
  text-decoration: none;
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

nav a:hover {
  background-color: #2a4f3e;
}

.fixed-width-btn {
  width: 150px; /* Fixed width */
  height: 40px; /* Fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; /* Remove padding to maintain size */
  font-size: 14px; /* Adjust font size if needed */
  position: relative; /* Position relative for spinner */
}

.fixed-width-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.tenant-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh; /* Adjust as needed to center vertically */
}

.tenant-table-container .spinner {
  position: static; /* Override the absolute positioning for this specific spinner */
  margin: 0; /* Remove any margin to center it perfectly */
}

.help-content {
  text-align: left;
  margin: 20px 0;
  color: white;
}

.help-content h3 {
  color: #4caf50;
  margin: 15px 0 10px 0;
  text-align: left;
}

.help-content ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
}

.help-content li {
  margin: 5px 0;
}

.modal {
  width: 800px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}
