.help-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f5f5f5;
  color: white;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 800px;
  max-width: 90vw;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 1000;
  pointer-events: auto;
  user-select: none;
}

.help-modal-header {
  padding: 15px 20px;
  cursor: move;
  background-color: #45a049;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.help-modal-header h2 {
  margin: 0;
  padding: 0;
}

.help-content {
  padding: 20px 30px;
}

.help-content h3 {
  color: #2e7d32;
  margin: 20px 0 10px 0;
  font-size: 20px;
}

.help-content p {
  margin-bottom: 15px;
  line-height: 1.6;
  color: black;
}

.help-content ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  color: black;
}

.help-content li {
  margin: 8px 0;
  line-height: 1.4;
}

.help-modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.help-modal .btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  min-width: 120px;
}

.help-modal .btn:hover {
  background-color: #45a049;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 8px 12px;
  line-height: 1;
  transition: color 0.3s;
  z-index: 1;
}

.close-button:hover {
  color: #2a4f3e;
}